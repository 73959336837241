<script>
import { defineComponent, ref } from 'vue'

import Logo from '@/assets/logo.svg'

export default defineComponent({
  name: 'ThePreloader',
  components: {
    Logo
  },
  setup () {
    const visible = ref(true)

    return {
      visible
    }
  }
})
</script>

<template>
  <transition>
    <div v-if="visible" id="app-loading">
      <Logo class="logo" />
      <div class="ball-wrapper">
        <div v-for="i in 7" :key="i" class="ball"></div>
      </div>
    </div>
  </transition>
</template>

<style lang="scss" scoped>
#app-loading {
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  display: flex;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  padding: rem(40px 20px);
  background: #fff;
  color: #000;
  text-align: center;
}

.logo {
  display: block;
  width: 90%;
  max-width: rem(332px);
  height: auto;
  margin: 0 auto;
  color: #0063af;
}

.ball-wrapper {
  display: flex;
  width: 90%;
  max-width: rem(380px);
  justify-content: space-between;
}

.ball {
  position: relative;
  width: rem(35px);
  height: rem(35px);

  &:nth-child(2n+2) {
    @screen beforeMd {
      display: none;
    }
  }

  &::before,
  &::after {
    position: absolute;
    bottom: 0;
    left: 0;
    display: block;
    width: 100%;
    border-radius: 50%;
    content: "";
  }

  &::before {
    height: rem(20px);
    animation: shadow 0.5s infinite alternate ease-out;
    background: #ccc;
  }

  &::after {
    bottom: rem(5px);
    height: 100%;
    animation: bounce 0.5s infinite alternate ease-out;
    background: #f00;
  }

  @for $i from 1 through 7 {
    &:nth-child(#{$i}) {
      &::after {
        background-color: #{map.get($palette, list.nth(map.keys($palette), $i))};
      }

      &::before,
      &::after {
        animation-delay: (5 - $i) * -0.2s;
      }
    }
  }
}

@keyframes bounce {
  0% {
    transform: translateY(0%) scale(1.2, 0.8);
  }

  30% {
    transform: translateY(-30%) scale(1, 1);
  }

  100% {
    transform: translateY(-100%) scale(1, 1);
  }
}

@keyframes shadow {
  0% {
    transform: scale(0.8, 0.5);
  }

  100% {
    transform: scale(1);
  }
}
</style>
